import React from "react";

export default function OurStory() {
  return (
    <div>
      <h1>Once Upon A Time...</h1>
      <div>
        <p>Coming Soon...</p>
      </div>
    </div>
  );
}
